import { Helmet } from 'react-helmet';
import './Adhesives.scss';
import { onlineStoreWebsite, webTitle } from '../Constants';

const Adhesives =() => {
    window.scrollTo({ top: 0, behavior:'smooth'});

    return (
        <div className="flex-cc">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Ultimate Guide to Tile & Stone Adhesives - {webTitle}</title>
            </Helmet>
            <div style={{ maxWidth:'800px', width:'100%'}}>
                <h2 className='page-title' style={{padding:'20px'}}>Ultimate Guide to Tile & Stone Adhesives</h2>
                
                <br/><br/>
                
                <img src="/assets/images/ultimate-guide-to-adhesives.jpg" alt="ultimate guide to adhesives" className="hero-image"/>

                <br/><br/>
                <section style={{textAlign:'justify', padding:'20px'}}>
                    <p>Thinking about tiling your home? Whether it's a new bathroom, kitchen, or patio, picking the right adhesive is key to making sure your tiles stay put. Here's a guide to help you understand the basics and choose the best adhesive for your project.</p>
                    <p>Let's learn more about what is the difference between tiles & stones.</p>
                    
                    <br/><hr/><br/>

                    <h3 style={{textAlign:'center'}}>Tiles vs Stones</h3>
                    <p>
                        <strong>Tiles&nbsp;</strong>:&nbsp;
                        <span><em>Usually made from materials like ceramic, porcelain, and vitrified clay. They are durable and easy to clean.</em></span>
                    </p>
                    <p>
                        <strong>Stones&nbsp;</strong>:&nbsp;
                        <span><em>Natural materials like granite, marble, and sandstone. They look beautiful but may need more care.</em></span>
                    </p>
                    
                    <br/><hr/><br/>

                    <h3 style={{textAlign:'center'}}>Types of Tiles and Stones</h3>
                    <ol>
                        <li>
                            <p>
                                <strong>Ceramic Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Made from clay and other natural materials, these are great for indoor use.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Porcelain Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        A stronger type of ceramic tile, good for both indoor and outdoor use.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Vitrified Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Very durable and stain-resistant, perfect for busy areas.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Granites&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        A strong, natural stone with unique patterns. Often used for countertops and floors.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Marbles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        A luxurious natural stone, great for elegant interiors.
                                    </em>
                                </span>
                            </p>
                        </li>
                    </ol>

                    <br/><hr/><br/>

                    <h3 style={{textAlign:'center'}}>Types of Tile and Stone Adhesives</h3>
                    <ul>
                        <li>
                            <p>
                                <strong>Cement-Based Adhesives&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Good for most tiles and stones, both indoors and outdoors.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Epoxy Adhesives&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Very strong and durable, ideal for heavy-use areas and places with chemicals or moisture.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Acrylic Adhesives&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Easy to use for small projects and repairs, best for indoor use.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Polyurethane Adhesives&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Flexible and strong, perfect for outdoor projects where surfaces might move.
                                    </em>
                                </span>
                            </p>
                        </li>
                    </ul>
                </section>
                
                <br/><hr/><br/>

                <section style={{padding:'20px'}}>
                    <h3 style={{textAlign:'center'}}>Choosing the Right Adhesive {"(Based on IS:15477:2019)"}</h3>
                    <p style={{textAlign:'center'}}>Here's a simple table to help you pick the right adhesive for different areas of your home</p>
                    
                    <div style={{overflowX:"auto"}}>
                        <table className='adhesive-table'>
                            <thead className='table-head'>
                                <tr className='th-row'>
                                    <th className='th-title'>Where to Use</th>
                                    <th className='th-title'>Tile/Stone Size</th>
                                    <th className='th-title'>Tile/Stone Type</th>
                                    <th className='th-title'>Height/Area</th>
                                    <th className='th-title'>Standard Type</th>
                                </tr>
                            </thead>
                            <tbody className='table-body'>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Bathrooms</td>
                                    <td>Up to 600 x 600 mm</td>
                                    <td>Ceramic, Porcelain</td>
                                    <td>Floors and walls up to 3m</td>
                                    <td>Type 2T, Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Bathrooms</td>
                                    <td>600 x 1200 mm, 800 x 1600 mm, 1200 x 1800 mm</td>
                                    <td>Vitrified, Granite, Marble</td>
                                    <td>Floors and walls up to 3m</td>
                                    <td>Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Kitchens</td>
                                    <td>Up to 600 x 600 mm</td>
                                    <td>Ceramic, Porcelain</td>
                                    <td>Backsplashes and floors</td>
                                    <td>Type 2T, Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Kitchens</td>
                                    <td>600 x 1200 mm, 800 x 1600 mm, 1200 x 1800 mm</td>
                                    <td>Vitrified, Granite, Marble</td>
                                    <td>Backsplashes and floors</td>
                                    <td>Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Room Floors</td>
                                    <td>Up to 600 x 600 mm</td>
                                    <td>Ceramic, Porcelain</td>
                                    <td>General use</td>
                                    <td>Type 1, Type 1T, Type 2T</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Room Floors</td>
                                    <td>600 x 1200 mm, 800 x 1600 mm, 1200 x 1800 mm</td>
                                    <td>Vitrified, Granite, Marble</td>
                                    <td>General use</td>
                                    <td>Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Room Walls</td>
                                    <td>Up to 600 x 600 mm</td>
                                    <td>Ceramic, Porcelain</td>
                                    <td>Up to 3m</td>
                                    <td>Type 1, Type 1T, Type 2T</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Room Walls</td>
                                    <td>600 x 1200 mm, 800 x 1600 mm, 1200 x 1800 mm</td>
                                    <td>Vitrified, Granite, Marble</td>
                                    <td>Up to 3m</td>
                                    <td>Type 3T, Type 3TES1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Outdoor Floors</td>
                                    <td>600 x 600 mm and larger</td>
                                    <td>Vitrified, Granite</td>
                                    <td>High-traffic areas</td>
                                    <td>Type 3T, Type 3TES1, Type 4TS1</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Outdoor Walls</td>
                                    <td>600 x 600 mm and larger</td>
                                    <td>Vitrified, Granite</td>
                                    <td>Up to 3m</td>
                                    <td>Type 3TS1, Type 4TS1, Type 4TS2</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Heights {'>3m'}</td>
                                    <td>Any Size</td>
                                    <td>Any Type</td>
                                    <td>Strong bonding needed</td>
                                    <td>Type 3T, Type 4TS1, Type 4TS2</td>
                                </tr>
                                <tr className='tb-row'>
                                    <td className='font-bold'>Swimming Pools</td>
                                    <td>Any Size</td>
                                    <td>Any Type</td>
                                    <td>Submerged areas</td>
                                    <td>Type 3TES1, Type 4TS1, Type 4TS2</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                
                <br/><hr/><br/>

                <section style={{textAlign:'justify', padding:'20px'}}>
                    <h3 style={{textAlign:'left'}}>Explaining Adhesive Types {'(IS:15477:2019)'}</h3>
                    <ul>
                        <li>
                            <p>
                                <strong>Type 1&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Basic cement-based adhesive, suitable for dry indoor use with ceramic and porcelain tiles up to 600 x 600 mm.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 1T&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Cement-based adhesive with improved slip resistance, ideal for vertical dry applications up to 600 x 600 mm.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 2T&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Cement-based adhesive with improved flexibility and slip resistance, suitable for larger tiles and moderate traffic areas, including bathrooms and kitchens.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 3T&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        High-performance cement-based adhesive with excellent bonding strength and flexibility, suitable for large tiles and high-traffic areas.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 3TES1&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        High-performance epoxy adhesive with superior strength and chemical resistance, perfect for heavy-use areas, large tiles, and areas exposed to chemicals or submerged in water, such as swimming pools.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 3TS1&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Flexible polyurethane adhesive, suitable for exterior walls and areas with movement or vibration.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 4TS1&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Advanced polyurethane adhesive with high bonding strength and flexibility, suitable for large tiles, high-traffic, and exterior applications.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Type 4TS2&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Highest-grade polyurethane adhesive with maximum flexibility and strength, ideal for the largest tiles, extreme conditions, and critical applications.
                                    </em>
                                </span>
                            </p>
                        </li>
                    </ul>

                    <br/><hr/><br/>

                    <h3>How to Choose the Right Adhesive</h3>
                    <ul>
                        <li>
                            <p>
                                <strong>Ceramic Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Use cement-based (Type 1, Type 1T) for dry areas; epoxy (Type 3T, Type 3TES1) for wet or tough spots.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Porcelain Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Cement-based (Type 2T) for most dry and wet projects; epoxy (Type 3T, Type 3TES1) for outdoor and heavy-use areas.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Vitrified Tiles&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Use epoxy (Type 3T, Type 3TES1) for most applications.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Granite&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Use epoxy (Type 3T, Type 3TES1) for the best strength.
                                    </em>
                                </span>
                            </p>
                        </li>
                        <li>
                            <p>
                                <strong>Marble&nbsp;</strong>:&nbsp;
                                <span>
                                    <em>
                                        Cement-based (Type 1, Type 1T) for dry areas; epoxy (Type 3T, Type 3TES1) for busy and wet areas.
                                    </em>
                                </span>
                            </p>
                        </li>
                    </ul>

                    <br/><hr/><br/>

                    <h3>Conclusion</h3>
                    <p>Picking the right adhesive ensures your tiles and stones will stay in place and last a long time. Whether it's a bathroom, kitchen, or outdoor area, using the right product, like those from 
                        &nbsp;<a href={`${onlineStoreWebsite}/category/tile-adhesives`} style={{textDecoration:'none'}}>JKTYLO, MYK LATICRETE and ROFF</a>, 
                        &nbsp;makes a big difference.
                    </p>
                </section>
            </div>
        </div>
    )
}

export default Adhesives;