
import React, {useState} from "react";
import { LoadingCircleDiv } from "../../components/Loading/Loading";
import { QuestionRatingEmoji } from "./ShoppingFeedbackQuestions/Questions";
import {ReactComponent as HeartCircle} from "../../assets/svg/emoji/heart_emoji_circle.svg";
import { FN_ADD_SHOPPING_FEEDBACK, feedbackDiscordBotURL, webTitle } from "../Constants";
import './Feedback.scss';
import { Helmet } from "react-helmet";

export const ShoppingFeedbackForm =({orderId, uid}) =>{
    const [feedbackForm, setFeedbackForm] = useState(true);
    const [ratingValue, setRatingValue] = useState(null);
    const [feedbackDescription, setFeedbackDescription] = useState('');
    const [feedbackCategory, setFeedbackCategory] = useState('issue');
    const [loadingSubmitFeedback, setLoadingSubmitFeedback] = useState(false);
    const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

    const [proceedTo, setProceedTo] = useState(0);
    const flow = {
        0: 'form',
        1: 'personal-info',
        2: 'thank-you'
    }
    const [email, setEmail] = useState('');

    // const submitForm =async()=>{
    //     if(name.length>=3 && email.length && phone.length && message.length){
    //         if(phone){
    //             // Set loading to true ---- it will show loading screen
    //             setLoadingSubmitFeedback(true);
    //             const notified = await fetch(feedbackDiscordBotURL, {
    //                 method: 'POST',
    //                 headers: {'Content-Type':'application/json'},
    //                 body: JSON.stringify({
    //                     content: 'Someone gave us a feedback!',
    //                     embeds: [
    //                         {
    //                             title: 'Feedback Form Submitted',
    //                             type: 'rich',
    //                             fields: [
    //                                 {name:'Rating', value: ratingValue},
    //                                 {name:'More Details', value: feedbackDescription},
    //                                 {name:'Feedback Type', value: feedbackCategory},
    //                             ]
    //                         }
    //                     ]
    //                 })
    //             })
    //             console.log("\n\nNotified: ", notified.ok);
    //             if(notified.ok){
    //                 // Form Successfully Submitted
    //                 setLoadingSubmitFeedback(false);
    //                 setFeedbackSubmitted(true);
    //             }else{
    //                 // Form Not Submitted
    //                 setLoadingSubmitFeedback(false);
    //                 setFeedbackSubmitted(false);
    //             }
    //         }
    //     }
    // }


    const sendFeedback=async()=>{
        if(ratingValue!==null && feedbackDescription.length>3){

            // -------------------------------
            // Method 1 - Submit Feedback
            // -------------------------------
            // setLoadingSubmitFeedback(true);
            // // console.log('sendFeedback()');
            // // Cloud function to write feedback to planetScale db
            // fetch(FN_ADD_SHOPPING_FEEDBACK, {
            //     method:'post',
            //     headers: {"Content-Type": "application/json"},
            //     body: JSON.stringify({
            //         feedback: feedbackDescription,
            //         feedbackType: feedbackCategory,
            //         shopExperienceRating: ratingValue,
            //         orderId: orderId,
            //         customerUid: uid
            //     })
            // }).then(response=>response.json())
            // .then(data=>{
            //     if(data.status===1){
            //         // stop loading animation and show feedback submitted msg
            //         // also empty feedback description, ratingValue
            //         setLoadingSubmitFeedback(false);
            //         setFeedbackSubmitted(true);
            //         setFeedbackDescription('');
            //         setRatingValue(null);
            //     }else{
            //         // stop loading animation and show an error msg that feedback could not be submitted
            //         setLoadingSubmitFeedback(false);
            //         setFeedbackSubmitted(false);
            //     }
            // })
            // .catch(error=>{
            //     console.log("Something went wrong");
            //     setLoadingSubmitFeedback(false);
            //     setFeedbackSubmitted(false);
            // })


            // -----------------------------------
            // Method 2 - Submit Feedback
            // -----------------------------------
            // Set loading to true ---- it will show loading screen
            setLoadingSubmitFeedback(true);
            const notified = await fetch(feedbackDiscordBotURL, {
                method: 'POST',
                headers: {'Content-Type':'application/json'},
                body: JSON.stringify({
                    content: 'Someone gave us a feedback!',
                    embeds: [
                        {
                            title: 'Feedback Form Submitted',
                            type: 'rich',
                            fields: [
                                {name:'Feedback Type', value: feedbackCategory},
                                {name:'More Details', value: feedbackDescription},
                                {name:'Rating', value: ratingValue},
                                {name:'Email', value: email.length? email:'-'}
                            ]
                        }
                    ]
                })
            })
            console.log("\n\nNotified: ", notified.ok);
            if(notified.ok){
                // Form Successfully Submitted
                setLoadingSubmitFeedback(false);
                setFeedbackSubmitted(true);
                setProceedTo(proceedTo+1);
            }else{
                // Form Not Submitted
                setLoadingSubmitFeedback(false);
                setFeedbackSubmitted(false);
            }
        }
    }

    const formFlow = (value) =>{
        switch (value) {
            case 0: 
                return (
                    // Form
                    <form onSubmit={(e)=>{
                            e.preventDefault(); 
                            // sendFeedback();
                            setProceedTo(state=>(proceedTo+1))
                        }}
                    >
                        <div className='flex-cc' style={{width:'100%'}}>
                            {/* Feedback form Select Category */}
                            <span style={{marginRight:'20px'}}>Select Category</span>
                            <select onChange={(e)=>setFeedbackCategory(e.target.value)}
                                name="feedback"
                                style={{padding:'5px', fontSize:'1rem', background:'none', cursor:'pointer'}}
                                id="feedback"
                                value={feedbackCategory}
                                autoFocus
                            >
                                <option value="issue">Issue</option>
                                <option value="suggestion">Suggestion</option>
                                <option value="shopping">Shopping</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <br/>
                        <div className='flex-cc' style={{width:'100%'}}>
                            {/* Feedback form Know More... */}
                            <textarea 
                                value={feedbackDescription}
                                style={{background:'none', width:'80%', maxWidth:'80%', margin:'0 auto', height:'60px', fontSize:'1rem', padding:'0px 20px'}} 
                                placeholder='We want to know more...'
                                onChange={(e)=>setFeedbackDescription(e.target.value)}
                                minLength={3}
                            />
                        </div>
                        <div>
                            <QuestionRatingEmoji 
                                question='How was your experience?' 
                                ratingValue={ratingValue}
                                setRatingValue={setRatingValue}    
                            />
                        </div>
                        <br/>
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {/* <button className='outline-button' 
                                type='button' 
                                style={{fontSize:'0.8rem', marginRight:'20px'}} 
                                onClick={()=>{setRatingValue(null); setFeedbackForm(false)}}    
                            >
                                Cancel
                            </button> */}
                            <button className="feedback-button" 
                                type='submit' 
                                disabled={ratingValue!==null && feedbackDescription.length>3? false:true}
                                style={{fontSize:'0.8rem', textDecoration:'none'}}
                            >
                                Next
                            </button>
                        </div>
                    </form>
                );
            case 1 : 
                return (
                    // Personal Information
                    <form onSubmit={(e)=>{
                            e.preventDefault(); 
                            sendFeedback();
                        }}
                    >
                        <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                            <p style={{fontWeight:'600'}}>Want to hear back from us?</p>

                            <p style={{textAlign:'center'}}>Email <span style={{fontSize:'0.7rem'}}>{'(optional)'}</span></p>
                            <input 
                                    // style={{background:'none', borderBottom:'1px solid black', borderTop:'none', borderLeft:'none', borderRight:'none', padding:'4px'}}
                                    style={{background:'none', borderBottom:'1px solid black', borderLeft:'none', borderRight:'none', borderTop:'none', padding:'4px', fontSize:'1.1rem'}}
                                    type='email'
                                    value={email}
                                    placeholder="yours@email.com"
                                    onChange={(e)=>{setEmail(e.target.value)}}
                                />
                        </div>

                        <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                            {/* <button className='outline-button' 
                                type='button' 
                                style={{fontSize:'0.8rem', marginRight:'20px'}} 
                                onClick={()=>{setRatingValue(null); setFeedbackForm(false)}}    
                            >
                                Cancel
                            </button> */}
                            <button className="feedback-button" 
                                type='submit' 
                                disabled={ratingValue!==null && feedbackDescription.length>3? false:true}
                                style={{fontSize:'0.8rem', textDecoration:'none'}}
                            >
                                {email.length? 'Submit': 'Skip & Submit'}
                            </button>
                        </div>
                    </form>
                );
            case 2:
                return (
                    // Thank you page
                    <div className="flex-cc" style={{flexDirection:'column', margin:'20px', textAlign:'center'}}>
                        <div className="flex-cc" style={{width:'150px' , justifyContent:'space-around'}}>
                            <div style={{width:'30px'}}>
                                <HeartCircle />
                            </div>
                            <span style={{fontWeight:'600', fontSize:'1.2rem'}}>Thank you</span>
                        </div>
                        <span style={{fontSize:'0.8rem'}}>We have received your valuable feedback</span>
                    </div>
                );
            default: return null;
        }
    }
    return(
        <section style={{display:'flex', justifyContent:'center', alignItems:'center', width:'100%', marginTop:'30px'}}>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding:'0px 20px', width:'100%', maxWidth:'600px', flexDirection:'column'}}>
                <div style={{textAlign:'flex', justifyContent:'flex-end', alignItems:'center', width:'100%'}}>
                    {/* <div style={{marginBottom:'30px', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                        <a href={myOrders} className="action-button" style={{background:'none', border:'2px solid black', color:'black', fontSize:'0.9rem', fontWeight:'600', display:'flex', justifyContent:'space-between', width:'100px', alignItems:'center', textDecoration:'none'}}>
                            <div style={{width:'20px', height:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                                <BackArrow />
                            </div>
                            <span>My Orders</span>
                        </a>
                    </div> */}
                    {/* <div style={{display:'flex', flexDirection:'column', alignItems:'center', height:'100%', paddingTop:'30px'}}>
                        {
                            feedbackForm? null:
                                <p style={{fontSize:'0.8rem', textAlign:'center'}}>Tell us about your experience shopping with us, and help us improve</p>
                        }
                        <br/>
                        {
                            feedbackForm? null :
                                <span className="blue-link" style={{fontSize:'0.8rem', cursor:'pointer'}} onClick={()=>setFeedbackForm(true)}>
                                    Share Feedback
                                </span>
                        }
                    </div> */}
                </div>
                
                {
                    loadingSubmitFeedback?
                    <div>
                        <span style={{fontSize:'0.8rem'}}>Submitting your feedback</span>
                        <div style={{position:'relative', height:'10px', marginBottom:'30px'}}>
                            <LoadingCircleDiv center={true} scale='1.2'/>
                        </div>
                    </div>
                    :
                        <div style={{background:'rgba(0,0,0,0.05)', width:'100%', paddingBottom:'20px'}}>
                            {
                                !feedbackSubmitted && 
                                    <div style={{textAlign:'left', margin:'20px'}}>
                                        <span style={{fontWeight:'600', fontSize:'1.2rem'}}>We value your feedback</span><br/>
                                        <span style={{fontSize:'0.8rem'}}>Share your feedback and help us improve</span>
                                    </div>
                            }
                            {
                                formFlow(proceedTo)
                            }
                        </div>
                }
            </div>
        </section>
    )
}


export default function Feedback () {
    return (

        <div style={{display:'flex', justifyContent:'center'}}>
            <div style={{margin:'0 30px', paddingTop:'50px', textAlign:'justify', fontSize:'0.9rem', display:'flex', justifyContent:'center', maxWidth:'800px', width:'100%'}}>
                <Helmet>
                    <title>Feedback - {webTitle}</title>
                </Helmet>
                <section style={{minWidth:'100%'}}>
                    <div style={{display:'flex', flexDirection:'column', justifyContent:'flex-start', alignItems:'flex-start'}}>
                        <h1>Feedback</h1>
                        <p><strong>Your Feedback Matters!</strong> Please take a moment to share your feedback. Become a part of our journey and help us improve.</p>
                    </div>
                    <ShoppingFeedbackForm />
                </section>
            </div>
        </div>
    )
}